import React from 'react'
import { Link } from 'gatsby'
import ResentPost from '../components/parts/resentPost.js'
import SEO from '../components/parts/seo.js'
import Layout from '../components/layout/layout.js'
import Content from '../components/layout/content.js'

const Error404 = ({ location }) => (
  <React.Fragment>
    <SEO
      title='お探しのページは見つかりませんでした'
      description='お探しのページは見つかりませんでした'
      pathname={location.pathname}
      article
    />
    <Layout pageTitle={{ mainTitle: { lang: 'en', text: '<404 Not Found />' } }}>
      <Content.Lead>
        <p>
          お探しのページは見つかりませんでした。<br />
          削除されてしまったか、URLが間違っている可能性があります。
        </p>
        <div className="button button_center">
          <Link to="/" className="button__inner button__inner_main">トップページに戻る</Link>
        </div>
      </Content.Lead>
      <div className="content__item">
        <h2 className="title title_main">最新記事</h2>
        <ResentPost type="postlist" />
      </div>
    </Layout>
  </React.Fragment>
)
export default Error404;
